import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import Link from 'next/link';

import { cn } from '@/utils/cn';

import type { ReactNode } from 'react';

export interface Props {
    children?: ReactNode;
    className?: string;
    onClick?: () => void;
    link?: string;
}

export const WorkspaceSelectorDropdownEntry = ({ onClick, link, children, className }: Props) => {
    const clickable = !!onClick || !!link;

    const classes = cn(
        'flex min-h-10 w-full items-center gap-3 rounded-md py-2 pl-3 pr-4 text-sm text-gray-500 outline-none hover:text-gray-800',
        {
            'hover:bg-gray-100': clickable,
        },
        className,
    );

    if (link) {
        return (
            <DropdownMenu.Item asChild>
                <Link href={link} className={classes} onClick={onClick}>
                    {children}
                </Link>
            </DropdownMenu.Item>
        );
    }

    if (onClick) {
        return (
            <DropdownMenu.Item asChild>
                <button onClick={onClick} className={classes}>
                    {children}
                </button>
            </DropdownMenu.Item>
        );
    }

    return (
        <DropdownMenu.Item asChild>
            <div className={classes}>{children}</div>
        </DropdownMenu.Item>
    );
};
