import { NAME } from '@/app/navigation/constants';

import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';

import CommandMenu from '@/app/cmdk/components/CommandMenu';
import { Avatar } from '@/app/navigation/components/Navbar/Avatar';
import { BackButton } from '@/app/navigation/components/Navbar/BackButton';
import { InviteButton } from '@/app/navigation/components/Navbar/InviteButton';
import { Navbar } from '@/app/navigation/components/Navbar/Navbar';
import { TopLevelNav } from '@/app/navigation/components/Navbar/TopLevelNav';
import { WorkspaceSelector } from '@/app/navigation/components/Navbar/WorkspaceSelector';
import { SupportMenu } from '@/app/support/components/SupportMenu';
import { CapabilitiesTarget, useUserCapabilities } from '@/hooks/useUserCapabilities';
import { LoadingSpinner } from '@/ui/components/LoadingSpinner';
import { cn } from '@/utils/cn';
import { getIsWorkspaceEnv } from '@/utils/environments';

import RootLayout from '../RootLayout';

import type { NavbarStyle } from '@/app/navigation/components/Navbar/Navbar';
import type { ReactNode } from 'react';

export interface DefaultLayoutNewProps {
    children: ReactNode;
    style?: NavbarStyle;
    showWorkspaceSelector?: boolean;
    showTopLevelNavItems?: boolean;
    showWorkspaceHomeBackButton?: boolean;
    showInviteButton?: boolean;
    overrideWorkspaceHomeBackButtonLink?: string;
    isReactivation?: boolean;
    navbarTitle?: string;
}

const DefaultLayout = ({
    children,
    isReactivation = false,
    style = 'solid-fill',
    showWorkspaceHomeBackButton,
    overrideWorkspaceHomeBackButtonLink,
    showWorkspaceSelector,
    showTopLevelNavItems,
    showInviteButton,
    navbarTitle,
}: DefaultLayoutNewProps) => {
    const { events } = useRouter();
    const [navigating, setNavigating] = useState(false);
    const [navigatingDebounced, setNavigatingDebounced] = useState(false);

    const { canCreate: canCreateWorkspace } = useUserCapabilities(CapabilitiesTarget.Workspaces);
    const { canCreate: canInviteMembers } = useUserCapabilities(CapabilitiesTarget.Invites);

    const { t } = useTranslation(NAME);

    const isWorkspaceEnv = getIsWorkspaceEnv();
    const shouldShowDefaultTopLevelNav =
        showTopLevelNavItems && !isReactivation && !isWorkspaceEnv && canCreateWorkspace;

    const shouldShowInviteButton = showInviteButton && canInviteMembers;

    useEffect(() => {
        const handleStart = () => {
            setNavigating(true);
        };
        const handleStop = () => {
            setNavigating(false);
        };

        events.on('routeChangeStart', handleStart);
        events.on('routeChangeComplete', handleStop);
        events.on('routeChangeError', handleStop);

        return () => {
            events.off('routeChangeStart', handleStart);
            events.off('routeChangeComplete', handleStop);
            events.off('routeChangeError', handleStop);
        };
    }, [events]);

    useEffect(() => {
        if (navigating) {
            const timeout = setTimeout(() => {
                setNavigatingDebounced(true);
            }, 500);

            return () => {
                clearTimeout(timeout);
            };
        } else {
            setNavigatingDebounced(false);
        }
    }, [navigating]);

    return (
        <RootLayout>
            <Navbar style={style}>
                <div className="flex flex-1 items-center">
                    {showWorkspaceHomeBackButton && (
                        <BackButton overrideBackLink={overrideWorkspaceHomeBackButtonLink} />
                    )}

                    {navbarTitle && (
                        <p className="px-4 text-sm font-semibold text-gray-600">{t(navbarTitle)}</p>
                    )}

                    {showWorkspaceSelector && <WorkspaceSelector />}
                </div>

                {shouldShowDefaultTopLevelNav && <TopLevelNav />}

                <div className="flex flex-1 items-center justify-end">
                    <div className="flex items-center max-md:hidden">
                        {shouldShowInviteButton && <InviteButton />}
                    </div>

                    <Avatar />
                </div>
            </Navbar>

            <div
                className={cn(
                    'fixed inset-0 z-40 grid size-full place-items-center opacity-0 backdrop-blur-[2px] transition-all',
                    {
                        'opacity-100': navigatingDebounced,
                        'pointer-events-none': !navigatingDebounced,
                    },
                )}
            >
                {navigatingDebounced && <LoadingSpinner className="size-8" />}
            </div>

            {children}

            {!isReactivation && canCreateWorkspace && <SupportMenu />}
            {!isReactivation && <CommandMenu />}
        </RootLayout>
    );
};

export default DefaultLayout;
