import { NAME } from '@/app/navigation/constants';

import { Cog6ToothIcon, UsersIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'next-i18next';

import { WorkspaceSelectorDropdownEntry } from '@/app/navigation/components/Navbar/WorkspaceSelector/WorkspaceSelectorDropdownEntry';

export interface Props {
    manageMembersClicked: () => void;
    workspaceSettingsClicked: () => void;
}

export const WorkspaceSelectorActions = ({
    manageMembersClicked,
    workspaceSettingsClicked,
}: Props) => {
    const { t } = useTranslation(NAME);

    return (
        <>
            <div className="p-2">
                <WorkspaceSelectorDropdownEntry onClick={manageMembersClicked}>
                    <UsersIcon className="size-4" />
                    <span>{t('manage-members')}</span>
                </WorkspaceSelectorDropdownEntry>

                <WorkspaceSelectorDropdownEntry onClick={workspaceSettingsClicked}>
                    <Cog6ToothIcon className="size-4" />
                    <span>{t('workspace-settings')}</span>
                </WorkspaceSelectorDropdownEntry>
            </div>

            {/* Divider */}
            <div className="h-[1px] bg-gray-200"></div>
        </>
    );
};
