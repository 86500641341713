import { NAME } from '@/app/navigation/constants';

import Link from 'next/link';
import { useTranslation } from 'next-i18next';

import { cn } from '@/utils/cn';

import type { ReactNode } from 'react';

export interface NavItemProps {
    icon: ReactNode;
    link: string;
    labelKey: string;
    active: boolean;
    onClick?: () => void;
}

export const NavItem = ({ icon, labelKey, link, active, onClick }: NavItemProps) => {
    const { t } = useTranslation(NAME);

    return (
        <Link
            href={link}
            className={cn('flex h-10 items-center gap-2 rounded-lg px-3 py-2.5 transition', {
                'text-gray-500 hover:bg-gray-100 hover:text-gray-800': !active,
                'bg-gray-100 font-medium text-gray-800': active,
            })}
            onClick={onClick}
        >
            {icon}
            <span className="text-sm max-lg:hidden">{t(labelKey)}</span>
        </Link>
    );
};
