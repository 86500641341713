import { WorkspaceSetting } from '../../types';

export const encodeUrlStringToWorkspaceSetting = (setting: string | string[]) => {
    if (setting === '') {
        return WorkspaceSetting.General;
    }

    if (Array.isArray(setting)) {
        return setting[0].toUpperCase() as WorkspaceSetting;
    }

    return setting.toUpperCase() as WorkspaceSetting;
};

export const decodeWorkspaceSettingToUrlString = (setting: WorkspaceSetting) => {
    return setting.toLowerCase();
};

export const getWorkspaceSettingsLinks = (
    activeWorkspaceId: string,
): Record<WorkspaceSetting, string> => ({
    [WorkspaceSetting.General]: `/workspaces/${activeWorkspaceId}/settings`,
    [WorkspaceSetting.Icon]: `/workspaces/${activeWorkspaceId}/settings/${decodeWorkspaceSettingToUrlString(WorkspaceSetting.Icon)}`,
    [WorkspaceSetting.People]: `/workspaces/${activeWorkspaceId}/settings/${decodeWorkspaceSettingToUrlString(WorkspaceSetting.People)}`,
    [WorkspaceSetting.Members]: `/workspaces/${activeWorkspaceId}/settings/${decodeWorkspaceSettingToUrlString(WorkspaceSetting.Members)}`,
    [WorkspaceSetting.Preview]: `/workspaces/${activeWorkspaceId}/settings/${decodeWorkspaceSettingToUrlString(WorkspaceSetting.Preview)}`,
    [WorkspaceSetting.Funnel]: `/workspaces/${activeWorkspaceId}/settings/${decodeWorkspaceSettingToUrlString(WorkspaceSetting.Funnel)}`,
    [WorkspaceSetting.Funnels]: `/workspaces/${activeWorkspaceId}/settings/${decodeWorkspaceSettingToUrlString(WorkspaceSetting.Funnels)}`,
    [WorkspaceSetting.DeleteWorkspace]: `/workspaces/${activeWorkspaceId}/settings/${decodeWorkspaceSettingToUrlString(WorkspaceSetting.DeleteWorkspace)}`,
    [WorkspaceSetting.StatusTemplates]: `/workspaces/${activeWorkspaceId}/settings/${decodeWorkspaceSettingToUrlString(WorkspaceSetting.StatusTemplates)}`,
    [WorkspaceSetting.SenderSettings]: `/workspaces/${activeWorkspaceId}/settings/${decodeWorkspaceSettingToUrlString(WorkspaceSetting.SenderSettings)}`,
});
