import { LOLI_FEATURE_FLAG_NAME } from '@/core/loli-feature-flags/constants';

import { getLoliFeatureFlag, useLoliFeatureFlag } from '@/core/loli-feature-flags/store';

export const useRolesAndPermissionsEnabled = () => {
    return useLoliFeatureFlag(LOLI_FEATURE_FLAG_NAME.rolesAndPermissions);
};

export const isRolesAndPermissionsEnabled = () => {
    return getLoliFeatureFlag(LOLI_FEATURE_FLAG_NAME.rolesAndPermissions);
};
